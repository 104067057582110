<template>
    <div>
        <a-modal :title="getTitle" :width="600" :visible="visible" @cancel="handleCancel">
            <template slot="footer">
                <a-button key="back" @click="handleCancel">取消</a-button>
                <a-button key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">保存</a-button>
            </template>
            <a-spin :spinning="loading">
                <div class="bg-w">
                    <div>
                      <a-form layout="inline">
                        <a-form-model ref="ruleForm" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item ref="drug_name" label="药品名称" help="20字以内" required>
                                <a-input style="width: 360px;" v-model="form.drug_name"/>
                            </a-form-model-item>
                            <a-form-model-item ref="short_name" label="药品简称" help="20字以内" required>
                                <a-input style="width: 360px;" v-model="form.short_name"/>
                            </a-form-model-item>
                            <a-form-model-item label="药品用途" prop="purpose">
                                <a-input style="width: 360px;" v-model="form.purpose" type="textarea"/>
                            </a-form-model-item>
                            <a-form-model-item ref="frequency" label="使用频率" help="20字以内">
                                <a-input style="width: 360px;" v-model="form.frequency"/>
                            </a-form-model-item>
                            <a-form-model-item ref="metering" label="使用计量" help="20字以内">
                                <a-input style="width: 360px;" v-model="form.metering"/>
                            </a-form-model-item>
                        </a-form-model>
                      </a-form>
                    </div>
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>

<script>
    export default {
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
            drug_id: {
                type: Number,
                default: 0
            },
        },
        //defaultCheckedKeys
        data() {
            return {
                form: {
                    drug_id:0,
                    drug_name: '',
                    short_name: '',
                    purpose: '',
                    frequency: '',
                    metering: '',
                },
            }
        },
        computed: {
            getTitle() {
                if (this.group_id === 0) {
                    return "添加药品";
                } else {
                    return "编辑药品"
                }
            }
        },
        created() {
            this.form.drug_id = this.drug_id;
            this.loaddata();
        },
        methods: {
            loaddata() {
                if (this.loading === true) return;
                if(this.form.drug_id==0){ this.loading = false ;return;}
                this.loading = true;
                this.$http.api('platform/drug/show', {
                    drug_id: this.form.drug_id,
                }).then(res => {
                    this.form=res.detail;
                    this.loading = false;
                }).catch(res => {
                    console.log(res);
                    this.loading = false;
                })
            },

            /**
             * 取消弹窗
             */
            handleCancel() {
                this.$emit("cancel");
            },

            /**
             * 提交数据
             */
            onSubmit() {
                if (this.confirmLoading === true) return;
                this.confirmLoading = true;
                let p = this.form;
                this.$http.api('platform/drug/save', p).then(res => {
                    this.$message.success('操作成功', 1, () => {
                        this.confirmLoading = false;
                        this.$emit("ok");
                    })
                }).catch(res => {
                    console.log(res);
                    this.confirmLoading = false;
                })

            },
            onExpand(expandedKeys) {
                console.log('onExpand', expandedKeys);
                // if not set autoExpandParent to false, if children expanded, parent can not collapse.
                // or, you can remove all expanded children keys.
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onCheck(checkedKeys) {
                console.log('onCheck', checkedKeys);
                this.checkedKeys = checkedKeys;
            },
            onSelect(selectedKeys, info) {
                console.log('onSelect', info);
                this.selectedKeys = selectedKeys;
            },

        }
    }
</script>

<style>

</style>
