<template>
    <div>
        <div class="ft20 cl-main ftw600">药品信息库管理</div>

        <div class="mt20">
            <div class="bg-w">
                <div class="pd30">
                    <div>
                        <a-button type="primary" style="margin-right: 10px;" icon="plus" @click="addAct()">添加</a-button>
                        <a-button type="primary" icon="plus" @click="importAct()">导入</a-button>
                    </div>
                    <div class="form-search-box ">
                        <a-form layout="inline">
                            <a-form-item label="药品名称">
                                <a-input v-model="search.name" placeholder="请输入名称或者简称"></a-input>
                            </a-form-item>

                            <a-form-item>
                                <a-button @click="searchAct" type="primary">查询</a-button>
                                <a-button @click="cancelAct" class="ml10">取消</a-button>
                            </a-form-item>
                        </a-form>
                    </div>

                    <div class="">
                        <div class="wxb-table-gray">
                            <a-table rowKey="id"  :columns="columns" :pagination="pagination" @change="handleTableChange"
                                     :data-source="datas" :loading="loading">
                                <template slot="action" slot-scope="record,index">
                                    <div class="flex center">
                                        <a-dropdown placement="bottomRight">
											<span class="more-act">
												<i class="iconfont iconmore_gray"></i>
											</span>
                                            <a-menu slot="overlay">
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="editAct(record)">
                                                        <i class="iconfont ft14 iconedit"></i>
                                                        <span class="ml10">编辑</span>
                                                    </a>
                                                </a-menu-item>
                                                <a-menu-item>
                                                    <a class="menu-act" href="javascript:;" @click="delAct(record)">
                                                        <i class="iconfont ft14 icondelete"></i>
                                                        <span class="ml10">删除</span>
                                                    </a>
                                                </a-menu-item>
                                            </a-menu>
                                        </a-dropdown>
                                    </div>
                                </template>
                            </a-table>
                        </div>
                    </div>

                    <div v-if="editVisible">
                        <edit :visible="editVisible" :drug_id="drug_id" @cancel="cancelEdit" @ok="okEdit"></edit>
                    </div>
                    <div v-if="importXlsLoading">
                        <importxls :visible="importXlsLoading" @cancel="cancelImportXls" @ok="okImportXls"></importxls>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {listMixin} from '@/common/mixin/list';
    import edit from './components/drug/edit.vue';
    import importxls from './components/drug/improt.vue';
    export default{
        mixins: [listMixin],
        components:{
            edit,
            importxls,
        },
        data(){
            return{
                loading: false,
                editVisible:false,
                importXlsLoading:false,
                pagination: {
                    current: 1,
                    pageSize: 10, //每页中显示10条数据
                    total: 0,
                },
                drug_id:0,
                search:{
                    name:'',
                },
                columns: [
                    {title: '药品编号',dataIndex: 'drug_id',align: 'center'},
                    {title: '药品全称',dataIndex: 'drug_name',align: 'center',ellipsis: true},
                    {title: '药品简称',dataIndex: 'short_name',align: 'center'},
                    {title: '使用频率',dataIndex: 'frequency',align: 'center',ellipsis: true},
                    {title: '使用计量',dataIndex: 'metering',align: 'center',ellipsis: true},
                    {title: '创建时间',dataIndex: 'create_time',align: 'center',ellipsis: true},
                    {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
                ],
                datas: [],
            }
        },
        methods:{
            getLists(){
                if(this.loading==true) return;
                this.loading=true;
                this.$http.api('platform/drug/lists',{
                    limit:this.pagination.pageSize,
                    page:this.pagination.current,
                    name:this.search.name,
                }).then(res=>{
                    this.pagination.total=res.total;
                    this.datas=res.lists;
                    this.loading=false;
                }).catch(res=>{
                    this.loading=false;
                })
            },

            delAct(record){
                this.$confirm({
                    title:'确认删除这个药品吗？',
                    okText:"确定",
                    okType:"danger",
                    cancelText:"取消",
                    onOk : ()=>{
                        return new Promise((resolve,reject)=>{
                            this.$http.api('platform/drug/delete',{
                                drug_id:record.drug_id,
                            }).then(res=>{
                                this.$message.success('删除成功',1,()=>{
                                    this.getLists();
                                });
                            }).catch(res=>{
                                console.log(res);
                            }).finally(()=>{
                                resolve();
                            })
                        })
                    }
                })
            },
            okImportXls(){
                this.importXlsLoading=false;
                this.pagination.current=1;
                this.getLists();
            },
            importAct(){
                this.importXlsLoading=true;
            },
            cancelImportXls(){
                this.importXlsLoading=false;
            },
            addAct(){
                this.drug_id=0;
                this.editVisible=true;
            },
            editAct(record){
                this.drug_id=record.drug_id;
                this.editVisible=true;
            },
            cancelEdit(){
                this.editVisible=false;
            },
            okEdit(){
                this.editVisible=false;
                this.getLists();
            },
            handleTableChange(pagination, filters, sorter) {
                this.pagination.current = pagination.current;
                this.getLists();
            },
        }
    }
</script>

<style>
    .projetc-index-cover-img{
        width: 80px;
        height: 60px;
    }
    .project-type-icon img{
        width: 60px;
        height: 60px;
    }
</style>
